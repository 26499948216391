// .page-container {
// 	max-width: 700px;
// 	margin-top: 93px;
// }
.my-91 {
	margin-bottom: 200px;
	margin-top: 93px;
}
.header {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 351px;
}

.header-nav {
	border-top: 5px solid $blue;
	height: 70px;
	background-color: rgba(255,255,255,0.9);
}

.header-title {
	color: $blue;
	font-family: "PT Sans";
	font-size: 20px;
	text-transform: uppercase;
	font-weight: bold;
	padding: 19px 37px 17px 36px;
}

.header-image-title {
	color: #002663;
	font-family: "PT Sans";
	font-size: 42px;
	font-weight: bold;
	line-height: 55px;
}
.text-area {
	background-color: white;
	padding: 19px 17px 17px 20px;
	// min-height: 125px;
	font-size: 14px;
}
