.custom-input {
	padding-left: 14px;
	width: 100%;
	border: 1px solid $gray-300;

	&:hover {
		background-color: $gray-200;
	}

	&:active ~ .label-text {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
	}

	&:focus {
		border-left: 5px solid $blue;
	}

	&:focus ~ .label-text {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
		color: $blue;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}
}
small {
	font-size: $font-size-sm !important;
	color: $gray-700;
	line-height: 19px;
}
.custom-input.has-value {
	border-left: 5px solid $blue;
}

.custom-input.has-value ~ .label-text {
	-webkit-transform: translate(0, -20px);
	transform: translate(0, -20px);
	color: $blue;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
}

.formField,
.textArea {
	position: relative;
}

.formField-email input, .formField-firstname input, .formField-lastname input, .formField-textfield.company-info input, .formField-company.company-info input {
	height: 40px;
}

.formField-company {
	width: 48%;
	float: left;
	margin-right: 4%;
}

.formField-textfield {
	width: 48%;
	float: left;
}

.company-wrap::after {
	content: "";
  display: table;
  clear: both;
}

.display-none {
	display: none;
}
.border-top {
	border-top: 1px solid $gray-400;
	padding-bottom: 34px;
}
.some-link:hover {
	text-decoration: none;
}
.some-buttons:hover {
	-moz-transform: translate(0px, -3px);
	-ms-transform: translate(0px, -3px);
	-o-transform: translate(0px, -3px);
	/* -webkit-transform: translate(-2px, -2px); */
	transform: translate(0px, -3px);
}
.some-buttons {
	transition: transform 250ms ease, color 250ms ease, -webkit-transform 250ms ease;
	color: white;
	text-align: center;
	padding-top: 13px;
	padding-bottom: 13px;
	font-size: 12px;
}
.some-buttons i {
	font-size: 20px;
}
.button-text {
	margin-left: 5px;
}
.some-button-Facebook {
	background-color: #3B5998;
}
.some-button-Twitter {
	background-color: #1DA1F2;
}
.some-button-LinkedIn {
	background-color: #0077B5;
}
.some-button-Instagram {
	background-color: #D82D79;
}
.container-w-padding {
	padding: 0px 17px 17px 20px;
}
.form {
	padding: 0px 17px 17px 20px;
}
.editor-container {
	padding: 0px 17px 17px 20px;
	line-height: 19px;
}
.form-custom-header {
	color: #3B3B3B;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
}

.label-text {
	pointer-events: none;
	top: 15px;
	left: 0;
	margin: 0;
	position: absolute;
	-webkit-transform: translate(14px, 10px);
	transform: translate(14px, 10px);
	transition: transform 250ms ease, color 250ms ease, -webkit-transform 250ms ease;
	font-size: $font-size-sm;
	color: $gray-600;
}

.form-image {
	height: 68px;
	flex: 0 0 68px;
}

.form-image-briefcase {
	background-color: #046A38;
}

.form-image-hammer {
	background-color: #050806;
}

.form-image-brush {
	background-color: #F54890;
}

.form-image-euro {
	background-color: #0072CE;
}

.form-image-people {
	background-color: #582C83;
}

.form-image-plane {
	background-color: #998F82;
}

.form-text {
	padding-left: 19px;
	font-size: $font-size-sm;
	line-height: 19px;
	padding-right: 9px;
	// width: 127px;
	margin-top: inherit;
	color: $gray-800;
	hyphens: manual;
}
@media (max-width: 992px) {
	.custom-checkbox-label {
		width: 100%;
	}
}

.label-textarea {
	margin-top: 8px;
}

.label-textarea-text {
	font-size: 12px;
	color: $gray-700;
}

.custom-label {
	border: 1px solid #DDDDDD;
	cursor: pointer;
	background-color: #FFFFFF;
}

.custom-label:hover {
	background-color: #F1F1F1;
}

.custom-checkbox-label input:checked ~ .custom-label {
	border: 1px solid #002663;
}

.label-styles {
	height: 70px;
}

.custom-checkbox-label input {
	opacity: 0;
	z-index: -1;
	position: absolute;
}

.custom-checkbox input {
	opacity: 0;
	z-index: -1;
	position: absolute;
}

.image-text {
	color: #002663;
	font-size: 16px;
	font-weight: bold;
	line-height: 20px;
	text-align: center;
}

.custom-box {
	position: absolute;
	cursor: pointer;
	top: 2px;
	right: 17px;
	width: 20px;
	height: 20px;
	border: 1px solid $gray-300;
	transition-duration: 0.3s;
}

.custom-checkbox input:checked ~ .custom-box {
	background-color: #E18216;
}

.custom-checkbox input:checked ~ .custom-box:after {
	display: block;
}

/* Tietokirje */
.btn-tietokirje:hover {
    background-color: #3b1754;
}
.btn-tietokirje {
    background-color: #4f2170;
    color: #ffffff;
}

.image-text-tietokirje {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
}

.custom-label-tieto {
	    cursor: pointer;
			-webkit-transition: background-color 250ms linear;
			transition: background-color 250ms linear;
}

.custom-label-tieto:hover .formCheckboxLabel {
	text-decoration: underline;
}
.custom-label-tieto.pink-bg {
		color: #f94f8e;
		border: 2px solid #f94f8e;
}
.custom-label-tieto.purple-bg {
		color: #4f2170;
		border: 2px solid #4f2170;
}
.custom-label-tieto.brown-bg {
		color: #998F82;
		border: 2px solid #998F82;
}
.custom-label-tieto.blue-bg {
		color: #0092ef;
		border: 2px solid #0092ef;
}
.custom-label-tieto.green-bg {
		color: #046a38;
		border: 2px solid #046a38;
}

/* checked */

.custom-checkbox-label input:checked ~ .custom-label-tieto.pink-bg {
	text-decoration: underline;
	background-color: #f94f8e;
	color: #ffffff;
}
.custom-checkbox-label input:checked ~ .custom-label-tieto.purple-bg {
	text-decoration: underline;
	background-color: #4f2170;
	color: #ffffff;
}
.custom-checkbox-label input:checked ~ .custom-label-tieto.brown-bg {
	text-decoration: underline;
	background-color: #998F82;
	color: #ffffff;
}
.custom-checkbox-label input:checked ~ .custom-label-tieto.blue-bg {
	text-decoration: underline;
	background-color: #0092ef;
	color: #ffffff;
}
.custom-checkbox-label input:checked ~ .custom-label-tieto.green-bg {
	text-decoration: underline;
	background-color: #046a38;
	color: #ffffff;
}

@media (max-width: 425px) {
	.formField-company.company-info, .formField-textfield.company-info {
		width: 100%;
		float: none;
		margin-right: 0;
	}
}
